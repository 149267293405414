<template>
<div class="row">
  <div class="d-flex">
      <button class="searchButton cbFormTextField-sm mt-2" type="button"
              @click="$router.push({name: 'onboardingConfigs'})">
        Config List
    </button>
      <button class="searchButton cbFormTextField-sm mt-2" type="button"
              @click="$router.push({name: 'onboardingConfig', params: { id: 'new' }})">
        New Config
    </button>
  </div>
  <div class="d-flex">
    <button class="searchButton cbFormTextField-sm mt-2" type="button"
            @click="$router.push({name: 'ingestions'})">
        Ingestions
    </button>
    <button class="searchButton cbFormTextField-sm mt-2" type="button"
            @click="$router.push({ name: 'ingestion', params: { id: 'new' }})">
        New Ingestion
    </button>
  </div>
  <div class="d-flex">
    <button class="searchButton cbFormTextField-sm mt-2" type="button"
             @click="$router.push({name: 'dataRequests'})">
        Data Requests
    </button>
    <button class="searchButton cbFormTextField-sm mt-2" type="button"
            @click="$router.push({ name: 'dataRequest', params: { id: 'new' }})">
        New Data Request
    </button>
  </div>
</div>
</template>

<script setup lang="ts">


</script>

<style scoped>

</style>