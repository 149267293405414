<template>
    <div>
        <TheHeader />
        <div v-if="betaFeaturesEnabled">
            <SidebarNavigation :menus="sidebarMenus" :collapsed="isCollapsed" :activeMenu="activeMenu"
                @menuSelected="handleMenuSelected" @collapseChanged="handleCollapsed" />
            <div :class="['sidebar-content-wrapper', { 'sidebar-collapsed': isCollapsed }]">
                <div class="sidebar-content">
                    <Card>
                        <template #content>
                            <CandidateListNew :queue="activeMenu" :key="activeMenu" />
                        </template>
                    </Card>
                </div>
            </div>
        </div>
        <div v-else>
            <!-- Candidate list sub menu -->
            <nav class="candidates-list-sub-menu">
                <ul>
                    <li class="candidates-list-sub-menu-a" @click="goToSubMenuStatus('inbox')"
                        :class="(routeQueue == 'inbox') ? 'active' : ''">
                        <img src="/images/icoInbox2.png" alt="inbox" class="candidates-list-sub-menu-icon">
                        Inbox
                    </li>
                    <li class="candidates-list-sub-menu-a" @click="goToSubMenuStatus('questionnaire')"
                        :class="(routeQueue == 'questionnaire') ? 'active' : ''">
                        <img src="/images/icoQueue2.png" alt="inbox" class="candidates-list-sub-menu-icon">
                        Questionnaire
                    </li>
                    <li class="candidates-list-sub-menu-a" @click="goToSubMenuStatus('unqualified')"
                        :class="(routeQueue == 'unqualified') ? 'active' : ''">
                        <img src="/images/icoUnqual.png" alt="inbox" class="candidates-list-sub-menu-icon">
                        Unqualified
                    </li>
                    <li class="candidates-list-sub-menu-a" @click="goToSubMenuStatus('snooze')"
                        :class="(routeQueue == 'snooze') ? 'active' : ''">
                        <img src="/images/icoSnooze3.png" alt="inbox" class="candidates-list-sub-menu-icon">
                        Snooze
                    </li>
                    <li class="candidates-list-sub-menu-a" @click="goToSubMenuStatus('archive')"
                        :class="(routeQueue == 'archive') ? 'active' : ''">
                        <img src="/images/icoArchive2.png" alt="inbox" class="candidates-list-sub-menu-icon">
                        Archive
                    </li>
                    <li class="candidates-list-sub-menu-a" @click="goToSubMenuStatus('monitor')"
                        :class="(routeQueue == 'monitor') ? 'active' : ''">
                        <img src="/images/icoMonitor4.png" alt="inbox" class="candidates-list-sub-menu-icon">
                        Monitor
                    </li>
                    <li class="candidates-list-sub-menu-a" @click="goToSubMenuStatus('all')"
                        :class="(routeQueue == 'all') ? 'active' : ''">
                        <img src="/images/icoAll4.png" alt="inbox" class="candidates-list-sub-menu-icon">
                        All
                    </li>
                    <li v-if="hasPermissionToViewUnreleasedIR" class="candidates-list-sub-menu-a"
                        @click="goToSubMenuStatus('unreleased_ir')"
                        :class="(routeQueue == 'unreleased_ir') ? 'active' : ''">
                        <img src="/images/icoReport.png" alt="inbox" class="candidates-list-sub-menu-icon">
                        Unreleased Reports
                    </li>
                </ul>
            </nav>

            <!-- Filtering -->
            <form @submit.prevent="submitFilter" @keydown.enter.prevent="submitFilter">
                <table class="candidates-list-filtering overflow-auto">
                    <tr class="p-2">
                        <td class="col-md-3 p-1">
                            <label for="filter-pin">
                                PIN
                                <abbr class="filter-tooltip" title="Remove dashes, spaces, & punctuation">
                                    <img src="/images/tooltip_icon_blue_outlined.png" alt="hint icon">
                                </abbr>
                            </label>
                        </td>
                        <td class="col-md-3 p-1">
                            <input id="filter-pin" type="text" maxlength="255"
                                v-model="candidatesListFilterForm.parcel_num" class="cbFormTextField" size="25">
                        </td>
                        <td class="col-md-3 p-1"><label for="filter-owner">Owner</label></td>
                        <td class="col-md-3 p-1">
                            <input type="text" id="filter-owner" maxlength="255"
                                v-model="candidatesListFilterForm.owner" class="cbFormTextField" size="25">
                        </td>
                        <td class="col-md-3 p-1"><label for="filter-deed-date">Qualification Date</label></td>
                        <td class="col-md-3 p-1">
                            <input type="date" id="filter-deed-date" maxlength="255"
                                v-model="candidatesListFilterForm.deed_date" class="cbFormTextField" size="25">
                        </td>
                        <td class="col-md-3 p-1"><label for="filter-exemptions">Exemption(s)</label></td>
                        <td class="col-md-3 p-1">
                            <input id="filter-exemptions" type="text" maxlength="255"
                                v-model="candidatesListFilterForm.exemptions" class="cbFormTextField" size="25">
                        </td>
                    </tr>
                    <tr class="p-2">
                        <td class="col-md-3 p-1"><label for="filter-flags">Flags</label></td>
                        <td class="col-md-3 p-1">
                            <select id="filter-flags" class="cbFormTextField" v-model="candidatesListFilterForm.flags">
                                <option value="" selected>Any</option>
                                <option v-for="flags in flagsOptions" :value="flags.value" :key="flags.value">
                                    {{ flags.text }}
                                </option>
                            </select>
                        </td>
                        <td class="col-md-3 p-1"><label for="filter-prev-queue">Prev Queue</label></td>
                        <td class="col-md-3 p-1">
                            <select id="filter-prev-queue" class="cbFormTextField"
                                v-model="candidatesListFilterForm.prev_queue">
                                <option value="" selected>-- Any --</option>
                                <option v-for="prevQueue in queueOptions" :value="prevQueue.value"
                                    :key="prevQueue.value">
                                    {{ prevQueue.text }}
                                </option>
                            </select>
                        </td>
                        <td class="col-md-3 p-1">
                            <label for="filter-taxable-value">
                                Taxable Value
                                <abbr class="filter-tooltip"
                                    title="Greater than or equal to this number. Numbers only in this field for best results.">
                                    <img src="/images/tooltip_icon_blue_outlined.png" alt="hint icon">
                                </abbr>
                            </label>
                        </td>
                        <td class="col-md-3 p-1">
                            <input id="filter-taxable-value" type="text" maxlength="255"
                                v-model="candidatesListFilterForm.taxable_value" class="cbFormTextField" size="25">
                        </td>
                        <td class="col-md-3 p-1">
                            <label for="filter-label">
                                Label
                                <abbr class="filter-tooltip" title="Filter for candidates associated with this label.">
                                    <img src="/images/tooltip_icon_blue_outlined.png" alt="hint icon">
                                </abbr>
                            </label>
                        </td>
                        <td class="col-md-3 p-1">
                            <input id="filter-label" type="text" maxlength="255"
                                v-model="candidatesListFilterForm.label" class="cbFormTextField" size="25">
                        </td>
                    </tr>
                </table>


                <table class="candidates-list-filtering overflow-auto">
                    <!-- Advanced Search -->
                    <tr class="">
                        <td class="labelCell formLabel" colspan="8">
                            <input type="checkbox" id="advanced-search" value="" class="advanced-search-checkbox"
                                style="margin-left: 0px !important" v-model="showAdvancedSearch" />
                            <label for="advanced-search">Advanced Search</label>
                        </td>
                    </tr>

                    <tr class="" v-if="showAdvancedSearch">
                        <td class="col-md-3">Homestead</td>
                        <td class="col-md-3">
                            <input type="radio" id="anyhomestead_flag" value="" class="advanced-search-radio"
                                v-model="candidatesListFilterForm.homestead_flag" />
                            <label for="anyhomestead_flag">Any</label>

                            <input type="radio" id="yeshomestead_flag" value="true" class="advanced-search-radio"
                                v-model="candidatesListFilterForm.homestead_flag" />
                            <label for="yeshomestead_flag">Yes</label>

                            <input type="radio" id="nohomestead_flag" value="false" class="advanced-search-radio"
                                v-model="candidatesListFilterForm.homestead_flag" />
                            <label for="nohomestead_flag">No</label>
                        </td>
                        <td class="col-md-3">Priority</td>
                        <td class="col-md-3 d-flex" id="priority-filter-field">
                            <div class="form-check mx-1">
                                <input class="form-check-input" type="checkbox" id="checkbox5" value="5"
                                    v-model="candidatesListFilterForm.priority_score">
                                <label class="form-check-label m-1" for="checkbox5">5</label>
                            </div>
                            <div class="form-check mx-1">
                                <input class="form-check-input" type="checkbox" id="checkbox4" value="4"
                                    v-model="candidatesListFilterForm.priority_score">
                                <label class="form-check-label m-1" for="checkbox4">4</label>
                            </div>
                            <div class="form-check mx-1">
                                <input class="form-check-input" type="checkbox" id="checkbox3" value="3"
                                    v-model="candidatesListFilterForm.priority_score">
                                <label class="form-check-label m-1" for="checkbox3">3</label>
                            </div>

                            <div class="form-check mx-1">
                                <input class="form-check-input" type="checkbox" id="checkbox2" value="2"
                                    v-model="candidatesListFilterForm.priority_score">
                                <label class="form-check-label m-1" for="checkbox2">2</label>
                            </div>
                            <div class="form-check mx-1">
                                <input class="form-check-input" type="checkbox" id="checkbox1" value="1"
                                    v-model="candidatesListFilterForm.priority_score">
                                <label class="form-check-label m-1" for="checkbox1">1</label>
                            </div>
                        </td>
                    </tr>

                    <tr class="" v-if="showAdvancedSearch">
                        <td class="col-md-3">Senior</td>
                        <td class="col-md-3">
                            <input type="radio" id="anysenior_flag" value="" class="advanced-search-radio"
                                v-model="candidatesListFilterForm.senior_flag" />
                            <label for="anysenior_flag">Any</label>

                            <input type="radio" id="yessenior_flag" value="true" class="advanced-search-radio"
                                v-model="candidatesListFilterForm.senior_flag" />
                            <label for="yessenior_flag">Yes</label>

                            <input type="radio" id="nosenior_flag" value="false" class="advanced-search-radio"
                                v-model="candidatesListFilterForm.senior_flag" />
                            <label for="nosenior_flag">No</label>
                        </td>

                        <td class="col-md-3">Created After</td>
                        <td class="col-md-3">
                            <input type="date" maxlength="255" id="created-after"
                                v-model="candidatesListFilterForm.identified_on" class="cbFormTextField" size="25">
                        </td>
                    </tr>

                    <tr class="" v-if="showAdvancedSearch">
                        <td class="col-md-3">Freeze</td>
                        <td class="col-md-3">
                            <input type="radio" id="anyfreeze_flag" value="" class="advanced-search-radio"
                                v-model="candidatesListFilterForm.freeze_flag" />
                            <label for="anyfreeze_flag">Any</label>

                            <input type="radio" id="yesfreeze_flag" value="true" class="advanced-search-radio"
                                v-model="candidatesListFilterForm.freeze_flag" />
                            <label for="yesfreeze_flag">Yes</label>

                            <input type="radio" id="nofreeze_flag" value="false" class="advanced-search-radio"
                                v-model="candidatesListFilterForm.freeze_flag" />
                            <label for="nofreeze_flag">No</label>
                        </td>
                        <td class="col-md-3">Ignore Trusts/LE</td>
                        <td class="col-md-3 p-2">
                            <input type="checkbox" id="anyignore_trusts" value="" class="advanced-search-checkbox"
                                v-model="candidatesListFilterForm.ignore_trusts" />
                        </td>
                    </tr>
                    <tr class="" v-if="showAdvancedSearch">
                        <td class="col-md-3">
                            Veteran
                        </td>
                        <td class="col-md-3">
                            <input type="radio" id="anyveteran_flag" value="" class="advanced-search-radio"
                                v-model="candidatesListFilterForm.veteran_flag" />
                            <label for="anyveteran_flag">Any</label>

                            <input type="radio" id="yesveteran_flag" value="true" class="advanced-search-radio"
                                v-model="candidatesListFilterForm.veteran_flag" />
                            <label for="yesveteran_flag">Yes</label>

                            <input type="radio" id="noveteran_flag" value="false" class="advanced-search-radio"
                                v-model="candidatesListFilterForm.veteran_flag" />
                            <label for="noveteran_flag">No</label>
                        </td>

                        <td class="col-md-3">
                            Applicant Type
                        </td>
                        <td class="col-md-3">
                            <select id="filter-applicant-type" class="cbFormTextField"
                                v-model="candidatesListFilterForm.owner_type">
                                <option value="" selected>-- Any --</option>
                                <option v-for="applicantType in applicantTypeOptions" :value="applicantType.value"
                                    :key="applicantType.value">
                                    {{ applicantType.text }}
                                </option>
                            </select>
                        </td>
                    </tr>
                    <tr class="" v-if="showAdvancedSearch">
                        <td class="col-md-3">
                            Disabled
                        </td>
                        <td class="col-md-3">
                            <input type="radio" id="anydisabled_flag" value="" class="advanced-search-radio"
                                v-model="candidatesListFilterForm.disabled_flag" />
                            <label for="anydisabled_flag">Any</label>

                            <input type="radio" id="yesdisabled_flag" value="true" class="advanced-search-radio"
                                v-model="candidatesListFilterForm.disabled_flag" />
                            <label for="yesdisabled_flag">Yes</label>

                            <input type="radio" id="nodisabled_flag" value="false" class="advanced-search-radio"
                                v-model="candidatesListFilterForm.disabled_flag" />
                            <label for="nodisabled_flag">No</label>
                        </td>
                        <td class="col-md-3" v-if="hasPermissionToReadCandidates">
                            Assigned To
                        </td>
                        <td class="col-md-3" v-if="hasPermissionToReadCandidates">
                            <select id="assigned-to" class="cbFormTextField" v-model="assignedToFilter">
                                <option value="" selected>-- Any --</option>
                                <option value="unassigned">-- Unassigned --</option>
                                <option v-for="assignedTo in assignedToList" :value="assignedTo.user_id"
                                    :key="assignedTo.user_id">
                                    {{ assignedTo.name }}
                                </option>
                            </select>
                        </td>
                    </tr>
                    <tr class="" v-if="showAdvancedSearch">
                        <td class="col-md-3">
                            Dis. Vet.
                        </td>
                        <td class="col-md-3">
                            <input type="radio" id="anydisabled_veteran_flag" value="" class="advanced-search-radio"
                                v-model="candidatesListFilterForm.disabled_veteran_flag" />
                            <label for="anydisabled_veteran_flag">Any</label>

                            <input type="radio" id="yesdisabled_veteran_flag" value="true" class="advanced-search-radio"
                                v-model="candidatesListFilterForm.disabled_veteran_flag" />
                            <label for="yesdisabled_veteran_flag">Yes</label>

                            <input type="radio" id="nodisabled_veteran_flag" value="false" class="advanced-search-radio"
                                v-model="candidatesListFilterForm.disabled_veteran_flag" />
                            <label for="nodisabled_veteran_flag">No</label>
                        </td>
                    </tr>
                </table>
                <table class="candidates-list-filtering overflow-auto" style="padding-top: 0px !important">
                    <!-- Specific Filters -->
                    <tr class="cbFormTableEvenRow">
                        <td class="labelCell formLabel text-bold" colspan="8">
                            <span class="filter">Queue-specific Filters</span>
                        </td>
                    </tr>
                    <tr class="cbFormTableEvenRow" v-if="routeQueue == 'inbox'">
                        <td class="labelCell formLabel">
                            <input type="checkbox" id="anynew" value="" class="advanced-search-checkbox"
                                style="margin-left: 0px !important" v-model="candidatesListFilterForm.new" />
                            <label for="anynew">New</label>
                        </td>
                    </tr>
                    <tr class="cbFormTableEvenRow" v-if="routeQueue == 'inbox'">
                        <td class="labelCell formLabel">
                            <input type="checkbox" id="anyhas_history" value="" class="advanced-search-checkbox"
                                style="margin-left: 0px !important" v-model="candidatesListFilterForm.has_history" />
                            <label for="anyhas_history">Has History</label>
                        </td>
                    </tr>
                    <tr class="cbFormTableEvenRow" v-if="routeQueue == 'archive'">
                        <td class="cbFormFieldCell" colspan="8">
                            <select class="cbFormTextField" id="final-outcome"
                                v-model="candidatesListFilterForm.final_outcome">
                                <option value="" selected>-- Final Outcome --</option>
                                <option v-for="finalOutcome in finalOutcomeOptions" :value="finalOutcome.text"
                                    :key="finalOutcome.value">
                                    {{ finalOutcome.text }}
                                </option>
                            </select>
                        </td>
                    </tr>
                    <tr class="cbFormTableEvenRow" v-if="routeQueue == 'unqualified'">
                        <td class="labelCell formLabel">
                            <select id="filter-lien-or-back-taxes" class="cbFormTextField"
                                v-model="candidatesListFilterForm.lien_or_back_taxes">
                                <option value="" selected>-- Back Taxes --</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </td>
                    </tr>
                    <tr class="cbFormTableEvenRow" v-if="routeQueue == 'unqualified'">
                        <td class="labelCell formLabel text-bold" colspan="8">
                            <span class="filter">Unqualified Year</span>
                        </td>
                    </tr>
                    <tr class="cbFormTableEvenRow" v-if="routeQueue == 'unqualified'">
                        <td class="labelCell formLabel">
                            <select id="filter-unqualified-start-year-gt" class="cbFormTextField"
                                v-model="candidatesListFilterForm.unqualified_start_year_gt">
                                <option value="" selected>Greater than or eq</option>
                                <option v-for="unqualifiedStartYear in unqualifiedStartYearOptions"
                                    :value="unqualifiedStartYear.value" :key="unqualifiedStartYear.value">
                                    {{ unqualifiedStartYear.text }}
                                </option>
                            </select>
                        </td>
                        <td class="labelCell formLabel">
                            <select id="filter-unqualified-start-year-lt" class="cbFormTextField"
                                v-model="candidatesListFilterForm.unqualified_start_year_lt">
                                <option value="" selected>Less than or eq</option>
                                <option v-for="unqualifiedStartYear in unqualifiedStartYearOptions"
                                    :value="unqualifiedStartYear.value" :key="unqualifiedStartYear.value">
                                    {{ unqualifiedStartYear.text }}
                                </option>
                            </select>
                        </td>
                    </tr>
                    <tr class="cbFormTableEvenRow" v-if="routeQueue == 'snooze'">
                        <td class="labelCell formLabel">
                            <label for="Value3_1">
                                After
                            </label>
                        </td>
                        <td class="cbFormFieldCell">
                            <input type="date" maxlength="255" id="snooze-until-after"
                                v-model="candidatesListFilterForm.snooze_until_after" class="cbFormTextField" size="25">
                        </td>
                    </tr>
                    <tr class="cbFormTableEvenRow" v-if="routeQueue == 'snooze'">
                        <td class="labelCell formLabel">
                            <label for="Value3_1">
                                Before
                            </label>
                        </td>
                        <td class="cbFormFieldCell">
                            <input type="date" maxlength="255" id="snooze-until-before"
                                v-model="candidatesListFilterForm.snooze_until_before" class="cbFormTextField"
                                size="25">
                        </td>
                    </tr>
                    <tr class="cbFormTableEvenRow mt-2" style="display: inline-block;">
                        <td class="labelCell formLabel">
                            <button type="button" @click="submitFilter" class="searchButton">Search</button>
                            <button type="button" @click="showClearSearchConfirmation = true" class="clearButton">Clear
                                Search</button>
                        </td>
                    </tr>
                </table>
            </form>
            <!-- List -->
            <div class="candidate-list">
                <CandidateList :requestHeaders="sendRequestHeaders" />
            </div>
        </div>
    </div>

    <ModalDialog v-if="showClearSearchConfirmation" title="Confirm Clear Search"
        :close="() => showClearSearchConfirmation = false">
        Are you sure you want to clear the search?
        <br>
        <br>
        This action cannot be undone, and all current search filters
        and input will be removed.

        <template #footer>
            <button @click="clearSearchField" class="btn confirmClearSearch">Confirm</button>
            <button style="border: 1px solid #ccc" @click="showClearSearchConfirmation = false"
                class="btn btn-default">Close</button>
        </template>
    </ModalDialog>
    <AppMonitor />
</template>

<script setup lang="ts">
import TheHeader from "@/components/TheHeader.vue"
import AppMonitor from "@/components/AppMonitor.vue"
import CandidateList from "@/components/Candidates/CandidateList.vue"
import CandidateListNew from "@/components/Candidates/CandidateListNew.vue"
import SidebarNavigation from "@/components/Shared/SidebarNavigation.vue"
import ModalDialog from "@/components/Shared/ModalDialog.vue"
import type { CandidateListRequestHeader, CandidateListFilterForm } from "@/helpers/interface/candidates"
import { queueOptions, flagsOptions, applicantTypeOptions, finalOutcomeOptions, unqualifiedStartYearOptions } from "@/helpers/dropdownOptions"
import { validateUserPermission, prepareFilterFieldObject, QueueCode, ucfirst, setPageTitle } from "@/helpers/common"
import { useRoute } from "vue-router"
import { ref, computed, watch, onMounted } from "vue"
import router from "@/router"
import type { Auth0User } from "@/helpers/interface/admin-page"
import { useAPI } from "@/helpers/services/api";
import type { SidebarNavigationMenu } from "@/helpers/interface/candidates"
import Card from 'primevue/card';
import { useProfile } from "@/stores/profile"

const route = useRoute()
const api = useAPI()
const storeProfile = useProfile()
let routeQueue = route?.params?.queue
const betaFeaturesEnabled = computed(() => storeProfile.enableBetaFeatures)
const userPermissionsLoadingState = ref(true)
watch(() => storeProfile.userPermissionsLoadingState, (loadingState: boolean) => {
    userPermissionsLoadingState.value = loadingState
})
const hasPermissionToReadCandidates = computed(() => validateUserPermission("read", "candidates"))
const hasPermissionToViewUnreleasedIR = computed(() => (validateUserPermission("read", "unreleased_investigation_reports")))
const activeMenu = ref(routeQueue as string)

const sidebarMenus = ref<SidebarNavigationMenu[]>([
    { label: "Inbox", iconClass: "fas fa-inbox", id: "inbox" },
    { label: "Snooze", iconClass: "fas fa-clock", id: "snooze" },
    { label: "Questionnaire", iconClass: "fas fa-question-circle", id: "questionnaire" },
    { label: "Unqualified", iconClass: "fas fa-user-times", id: "unqualified" },
    { label: "Monitor", iconClass: "fas fa-eye", id: "monitor" },
    { label: "All", iconClass: "fas fa-list", id: "all" },
    { label: "Archive", iconClass: "fas fa-archive", id: "archive" },
    { label: "Investigate Now", iconClass: "fas fa-file-circle-plus", id: "investigate_now" },
    { label: "Investigation Reports", iconClass: "fas fa-file-circle-check", id: "released_ir" },
])

const displayUnreleasedIRQueue = (show: boolean) => {
    if (show) sidebarMenus.value.push({ label: "Unreleased Reports", iconClass: "fas fa-file-pen", id: "unreleased_ir" })
}

watch(() => hasPermissionToViewUnreleasedIR.value, (canViewUnreleasedIR) => displayUnreleasedIRQueue(canViewUnreleasedIR))

const isCollapsed = ref(false)
const handleMenuSelected = (menuId: string) => {
    activeMenu.value = menuId
    setPageTitleForQueue(menuId)
    router.push({ name: "candidateList", params: { queue: activeMenu.value } });
}
const handleCollapsed = (collapsed: boolean) => isCollapsed.value = collapsed

const getQueueFilter = (queueName: QueueCode | string) => {
    if (queueName === QueueCode.All) {
        return {
            field: 'queue',
            type: 'in',
            value: [QueueCode.Inbox, QueueCode.Questionnaire, QueueCode.Unqualified, QueueCode.Snooze, QueueCode.Archive, QueueCode.Monitor]
        }
    } else {
        return { field: 'queue', type: '=', value: queueName }
    }
}

const showClearSearchConfirmation = ref<boolean>(false)
watch(() => route?.params?.queue, (queue) => routeQueue = queue)
let sendRequestHeaders = ref<CandidateListRequestHeader>({
    // Default values
    filter: [getQueueFilter(routeQueue as string)]
})

const showAdvancedSearch = ref(false)
const candidateFilterFields = {
    parcel_num: "",
    owner: "",
    deed_date: "",
    exemptions: "",
    flags: "",
    prev_queue: "",
    taxable_value: "",
    queue: "inbox",
    label: "",

    // Advanced Search
    homestead_flag: "",
    senior_flag: "",
    veteran_flag: "",
    disabled_flag: "",
    disabled_veteran_flag: "",
    freeze_flag: "",
    priority_score: [],
    identified_on: "",
    ignore_trusts: false, // ignore 'Trust' (does not contain)
    owner_type: "",

    // Specific filters
    new: true,
    has_history: true,
    final_outcome: "",
    snooze_until_after: "",
    snooze_until_before: "",
    lien_or_back_taxes: "",
    unqualified_start_year_gt: "",
    unqualified_start_year_lt: "",
}

const assignedToFilter = ref("")
const assignedToList = ref<Auth0User[]>([])
const candidatesListFilterForm = ref<CandidateListFilterForm>({ ...candidateFilterFields })

watch(() => assignedToFilter.value, (assignedToFilter) => {
    candidatesListFilterForm.value.unassigned = (assignedToFilter === "unassigned") ? true : false
    candidatesListFilterForm.value.assigned_to = (assignedToFilter !== "" && assignedToFilter !== "unassigned") ? assignedToFilter : null
})

watch(() => hasPermissionToReadCandidates.value, async (hasPermission) => {
    if (hasPermission) await fetchAssignee()
})

const submitFilter = () => {
    const candidatesListFilterFormValue = candidatesListFilterForm.value;

    if (!hasPermissionToReadCandidates.value) {
        candidatesListFilterFormValue.assigned_to = "me"
    }

    const filterFields = [
        { key: "pin", operator: "like", value: candidatesListFilterFormValue.parcel_num?.trim() ? candidatesListFilterFormValue.parcel_num + "%" : "" },
        { key: "owner", operator: "like", value: candidatesListFilterFormValue.owner },
        { key: "flags", operator: "like", value: candidatesListFilterFormValue.flags },
        { key: "exemptions", operator: "like", value: candidatesListFilterFormValue.exemptions },
        { key: "taxable_value", operator: ">=", value: candidatesListFilterFormValue.taxable_value },
        { key: "label", operator: "like", value: candidatesListFilterFormValue.label },
        { key: "qualification_date", operator: "<=", value: candidatesListFilterFormValue.deed_date ? new Date(candidatesListFilterFormValue.deed_date).toISOString().substring(0, 10) : null },
        { key: "prev_queue", operator: "=", value: candidatesListFilterFormValue.prev_queue },
        { key: "homestead_flag", operator: "=", value: candidatesListFilterFormValue.homestead_flag },
        { key: "senior_flag", operator: "=", value: candidatesListFilterFormValue.senior_flag },
        { key: "veteran_flag", operator: "=", value: candidatesListFilterFormValue.veteran_flag },
        { key: "disabled_flag", operator: "=", value: candidatesListFilterFormValue.disabled_flag },
        { key: "disabled_veteran_flag", operator: "=", value: candidatesListFilterFormValue.disabled_veteran_flag },
        { key: "freeze_flag", operator: "=", value: candidatesListFilterFormValue.freeze_flag },
        { key: "priority_score", operator: "in", value: candidatesListFilterFormValue.priority_score },
        { key: "identified_on", operator: ">=", value: candidatesListFilterFormValue.identified_on },
        { key: "owner_type", operator: "=", value: candidatesListFilterFormValue.owner_type },
        { key: "ignore_trusts", operator: "=", value: candidatesListFilterFormValue.ignore_trusts ? "true" : null },
        { key: "has_history", operator: "=", value: (candidatesListFilterForm.value.new && !candidatesListFilterForm.value.has_history) ? "false" : (!candidatesListFilterForm.value.new && candidatesListFilterForm.value.has_history) ? "true" : null },
        { key: "lien_or_back_taxes", operator: "=", value: candidatesListFilterFormValue.lien_or_back_taxes },
        { key: "unqualified_start_year__gte", operator: "=", value: candidatesListFilterFormValue.unqualified_start_year_gt },
        { key: "unqualified_start_year__lte", operator: "=", value: candidatesListFilterFormValue.unqualified_start_year_lt },
        { key: "snooze_until__gte", operator: "=", value: candidatesListFilterFormValue.snooze_until_after },
        { key: "snooze_until__lte", operator: "=", value: candidatesListFilterFormValue.snooze_until_before },
        { key: "final_outcome", operator: "=", value: candidatesListFilterFormValue.final_outcome },
        { key: "assigned_to", operator: "=", value: candidatesListFilterFormValue.assigned_to },
        { key: "unassigned", operator: "=", value: (!candidatesListFilterFormValue.unassigned) ? null : "true" }
    ];
    const filter = filterFields.filter(field => field.value !== "" && field.value !== null && field.value !== undefined && field.value.length !== 0).map(field => prepareFilterFieldObject(field.key, field.operator, field.value));

    const currentQueue = sendRequestHeaders.value.filter?.find(filterField => filterField.field === 'queue')?.value;
    if (currentQueue) {
        const operatorType = Array.isArray(currentQueue) ? "in" : "="
        filter.push(prepareFilterFieldObject("queue", operatorType, currentQueue));
    }

    sendRequestHeaders.value.filter = filter
}

const clearSearchField = () => {
    showClearSearchConfirmation.value = false
    candidatesListFilterForm.value = { ...candidateFilterFields }
    assignedToFilter.value = ""
    submitFilter()
}

const setPageTitleForQueue = (queue?: string) => {
    queue = queue || window.location.pathname.split("/").pop() || ""

    const queueMapping: Record<string, string> = {
        [QueueCode.InvestigateNow]: "Investigate Now",
        [QueueCode.InvestigationReports]: "Investigation Reports",
        [QueueCode.UnreleasedIR]: "Unreleased IR"
    }

    const queueName = queueMapping[queue] || ucfirst(queue)
    setPageTitle(`${queueName} - Candidates List`)
}

const goToSubMenuStatus = (queue: QueueCode | string) => {
    router?.push({ name: "candidateList", params: { queue } })
    clearSearchField()
    sendRequestHeaders.value.filter = [getQueueFilter(queue)]
    setPageTitleForQueue(queue)
}

const fetchAssignee = async () => {
    await api.get(`/users/auth0/?include_roles=false&blocked=false`)?.then(response => {
        const users = (response.data?.users || [])
        assignedToList.value = users
    })?.catch(() => {
        assignedToList.value = []
    })
}

onMounted(async () => {
    setPageTitleForQueue()
    if (hasPermissionToReadCandidates.value) await fetchAssignee()
    displayUnreleasedIRQueue(hasPermissionToViewUnreleasedIR.value)
})
</script>

<style>
@import "@/assets/candidates-list.css";
@media (min-width: 1110px) {
    .candidates-list-filtering {
        max-width: 1110px;
    }
}

#priority-filter-field .form-check .form-check-input {
    float: none;
}
</style>
